<template>
  <div class="pageContol listWrap questionBankOfBasicSet">
    <div class="framePage">
      <div class="framePage-title flexcb">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">题库管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">基本设置</a>
        </span>
      </div>
      <div class="framePage-body">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="智能试卷配置" name="first">
            <div class="framePage-scroll" style="height:100%">
              <div class="ovy-a">
                <div class="questionOption">
                  <el-form
                    ref="ruleForm"
                    :rules="rules"
                    label-position="right"
                    label-width="7rem"
                    :model="form"
                    style="width:70%"
                  >
                    <el-form-item
                      label="题型设置："
                      class="table_box"
                      prop="tableData"
                    >
                      <el-table
                        :data="tableData"
                        height="300"
                        size="small"
                        tooltip-effect="dark"
                        style="width: 100%"
                        :header-cell-style="tableHeader"
                        stripe
                      >
                        <el-table-column
                          prop="questionType"
                          label="题型"
                          width="180"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <span>{{ getType(scope.row.questionType) }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="questionNum"
                          label="数量"
                          width="180"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <el-input
                              ref="isNum"
                              v-if="scope.row.isNum"
                              v-model="scope.row.questionNum"
                              style="width:100%;hight:100%"
                              @blur="getState(scope.$index, 'isNum')"
                              @keyup.enter.native="
                                getState(scope.$index, 'isNum')
                              "
                            ></el-input>

                            <span
                              v-else
                              @click="getState(scope.$index, 'isNum')"
                              >{{ scope.row.questionNum }}</span
                            >
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="questionScore"
                          label="分值"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <el-input
                              ref="isScore"
                              v-if="scope.row.isScore"
                              v-model="scope.row.questionScore"
                              style="width:100%;hight:100%"
                              @blur="getState(scope.$index, 'isScore')"
                              @keyup.enter.native="
                                getState(scope.$index, 'isScore')
                              "
                            ></el-input>

                            <span
                              v-else
                              @click="getState(scope.$index, 'isScore')"
                              >{{ scope.row.questionScore }}</span
                            >
                          </template>
                        </el-table-column>
                        <el-table-column label="排序" align="center">
                          <template slot-scope="scope">
                            <i
                              class="sortUp"
                              v-if="scope.$index != 0"
                              @click="getSortUp(scope.$index)"
                            ></i>
                            <i
                              class="sortDown"
                              v-if="scope.$index != 3"
                              @click="getSortDown(scope.$index)"
                            ></i>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-form-item>
                    <el-form-item label="总分：" prop="configTotalScore">
                      <el-input
                        v-model="form.configTotalScore"
                        readonly
                      ></el-input>
                      <span style="margin-left:20px"
                        >根据题型设置里计算出来的</span
                      >
                    </el-form-item>
                    <el-form-item label="通过分数：" prop="configPassScore">
                      <el-input v-model="form.configPassScore"></el-input>
                    </el-form-item>
                    <el-form-item label="时间限制：" prop="configTimeLimit">
                      <el-input v-model="form.configTimeLimit"></el-input>
                      <span style="margin-left:20px">(分钟)</span>
                    </el-form-item>
                  </el-form>
                </div>
                <div
                  class="pageForm-btns"
                  style="position: absolute;bottom: 30px;left: 50%; "
                >
                  <el-button class="bgc-bv" @click="onSubmit">保存</el-button>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="banner图配置" name="second">
            <el-form
              label-position="right"
              label-width="10rem"
              :model="imgArr"
              ref="ruleFormImg"
              :rules="rulesImg"
            >
              <el-form-item label="上传banner图：" prop="imgArr">
                <div class="df">
                  <el-upload
                    v-for="(el, index) in imgArr"
                    :key="index"
                    class="banner_upload imgUpload img-el-upload"
                    style="width:140px;margin-right:20px"
                    :on-change="
                      res => {
                        handleAvatarSuccessIdPhoto(res, index);
                      }
                    "
                    :before-upload="$beforeAvatarUpload"
                    :http-request="$requestMine"
                    :show-file-list="false"
                    list-type="picture-card"
                    action
                    accept="image/png, image/gif, image/jpg, image/jpeg"
                  >
                    <div
                      v-if="el.bannerUrl"
                      class="delImg"
                      @click.stop="delImgFun(el.questionBankBannerId,index)"
                    ></div>
                    <el-image
                      v-if="el.bannerUrl"
                      :src="el.bannerUrl"
                      oncontextmenu="return false;"
                      ondragstart="return false;"
                      class="imgCenter"
                      style="width:100px;height:100px"
                    >
                    </el-image>

                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
                <div style="color: #999;">
                  用于APP练习页面里的轮播图展示，最多支持上传5张，建议上传尺寸为700*240
                </div>
              </el-form-item>
            </el-form>
            <div
              class="pageForm-btns"
              style="position: absolute;bottom: 30px;left: 50%; "
            >
              <el-button class="bgc-bv" @click="onSubmitImg">保存</el-button>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import moment from "moment";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "questionBankOfBasicSet",
  components: {},
  mixins: [List],
  data() {
    return {
      activeName: "first",
      isAdd: 0, //0新增 1 修改
      form: {
        tableData: [],
        configPassScore: "",
        configTimeLimit: "",
        configTotalScore: ""
      },
      rules: {
        tableData: [
          { required: true, validator: this.validatePass1, trigger: "blur" }
        ],
        configTotalScore: [
          { required: true, message: "请输入总分", trigger: "blur" }
        ],
        configPassScore: [
          { required: true, validator: this.validatePass3, trigger: "blur" }
        ],
        configTimeLimit: [{ validator: this.validatePass4, trigger: "blur" }]
      },
      rulesImg: {
        imgArr: [
          { required: true, validator: this.validatePass5, trigger: "blur" }
        ]
      },
      imgArr: []
    };
  },
  created() {},
  watch: {
    tableData: function() {
      let add = 0;
      this.tableData.forEach(item => {
        add += item.questionNum * item.questionScore;
      });
      this.form.configTotalScore = add;
    }
  },
  methods: {
    handleClick(e) {
      this.activeName = e.name;
      if (this.activeName == "first") {
        this.getData();
      } else if (this.activeName == "second") {
        this.getDataImg();
      }
    },
    init() {
      this.getData();
      this.getDataImg();
    },
    // 列表
    getData(pageNum = 1) {
      const params = {};
      this.$post("/baseconfig/questionTypeConfig/query", {}, 3000, true, 2)
        .then(res => {
          if (res.status == 0) {
            let config = [
              {
                intelligenceConfigId: "5",
                questionNum: "0",
                questionOrder: "1",
                questionScore: "0",
                questionType: "0"
              },
              {
                intelligenceConfigId: "5",
                questionNum: "0",
                questionOrder: "2",
                questionScore: "0",
                questionType: "1"
              },
              {
                intelligenceConfigId: "5",
                questionNum: "0",
                questionOrder: "3",
                questionScore: "0",
                questionType: "2"
              },
              {
                intelligenceConfigId: "5",
                questionNum: "0",
                questionOrder: "4",
                questionScore: "0",
                questionType: "3"
              }
            ];
            this.tableData = res.data.typeConfigs || config;
            console.log(this.tableData);
            // this.tableData =  config;
            this.isAdd = res.data.typeConfigs ? "1" : "0";

            this.tableData.forEach(element => {
              element.isNum = true;
              element.isScore = true;
            });
            this.form = {
              configPassScore: res.data.configPassScore || 0,
              configTimeLimit: res.data.configTimeLimit || 0,
              configTotalScore: res.data.configTotalScore || 0
            };
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(err => {
          return;
        });
    },
    getType(type) {
      let typeJson = [
        {
          value: "0",
          label: "单选题"
        },
        {
          value: "1",
          label: "多选题"
        },
        {
          value: "3",
          label: "填空题"
        },
        {
          value: "2",
          label: "判断题"
        }
      ];
      for (let i in typeJson) {
        if (typeJson[i].value == type) {
          return typeJson[i].label;
        }
      }
    },
    getState(index, state) {
      this.tableData[index].questionNum =
        this.tableData[index].questionNum || 0;
      this.tableData[index].questionScore =
        this.tableData[index].questionScore || 0;
      this.$set(this.tableData, index, this.tableData[index]);
    },
    //向上移动
    getSortUp(index) {
      let moveData = this.tableData[index];
      this.$set(this.tableData, index, this.tableData[index - 1]);
      this.$set(this.tableData, index - 1, moveData);
    },
    //向下移动
    getSortDown(index) {
      let moveData = this.tableData[index];
      this.$set(this.tableData, index, this.tableData[index + 1]);
      this.$set(this.tableData, index + 1, moveData);
    },
    validatePass1(rule, value, callback) {
      let reg = /^\d*(\.\d{1,2})?$/;
      // this.tableData
      let reg1 = /^\d+$/;
      for (let i in this.tableData) {
        let title = this.getType(this.tableData[i].questionType);
        if (!reg1.test(Number(this.tableData[i].questionNum))) {
          callback(new Error(title + "的数量为正整数"));
        } else if (Number(this.tableData[i].questionNum) > 0) {
          if (Number(this.tableData[i].questionScore) <= 0) {
            callback(new Error(title + "的数量大于0时，分值不能为0"));
          } else if (!reg.test(Number(this.tableData[i].questionScore))) {
            callback(new Error(title + "的分值最多保留两位小数"));
          } else {
            callback();
          }
        } else {
          callback();
        }
      }
    },
    validatePass3(rule, value, callback) {
      let reg = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^0$)|(^\d\.\d{1,2}$)/;
      console.log(Number(this.form.configPassScore) < 0);
      if (Number(this.form.configPassScore) < 0) {
        callback(new Error("通过分数必须正数"));
      } else if (
        Number(this.form.configPassScore) > Number(this.form.configTotalScore)
      ) {
        callback(new Error("通过分数不能大于总分"));
      } else if (!reg.test(Number(this.form.configPassScore))) {
        callback(new Error("通过分数最多保留两位小数"));
      } else {
        callback();
      }
    },
    validatePass4(rule, value, callback) {
      let reg = /^\d+$/;
      if (!reg.test(Number(this.form.configTimeLimit))) {
        callback(new Error("时间限制为正整数"));
      } else if (Number(this.form.configTimeLimit) < 0) {
        callback(new Error("时间限制应大于0"));
      }  else if (Number(this.form.configTimeLimit) > 2147483647) {
        callback(new Error("时间限制的数值过大"));
      }else {
        callback();
      }
    },
    onSubmit() {
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          let params = {
            ...this.form,
            typeConfigs: [...this.tableData]
          };
          let url =
            this.isAdd == "1"
              ? "/baseconfig/questionTypeConfig/modify"
              : "/baseconfig/questionTypeConfig/addBaseConfig";
          this.$post(url, params, 2000, true, 2)
            .then(res => {
              if (res.status == 0) {
                this.$message({
                  message: "操作成功",
                  type: "success",
                  duration: 1000
                });
              } else {
                this.$message.error(res.message);
              }
            })
            .catch(err => {
              return;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    handleAvatarSuccessIdPhoto(res, index) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then(result => {
            this.imgArr[index].bannerUrl = result.data.fileURL;
            this.imgArr[index].thumbnail = result.data.fileURL;
            this.imgArr[index].bannerContent = result.data.fileKey;
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败"
              });
            }, 300);
          });
      }
    },

    getDataImg(pageNum = 1) {
      const params = {};
      this.$post(
        "/baseconfig/questionTypeConfig/queryBaseBannerConfig",
        {},
        3000,
        true,
        2
      )
        .then(res => {
          if (res.status == 0) {
            let config = [];
            for (let i = 0; i < 5; i++) {
              config.push({
                bannerUrl: ""
              });
            }
            this.imgArr = [...res.data];
            let num = this.imgArr.length;
            for (let i = 0; i < 5 - num; i++) {
              this.imgArr.push({
                bannerUrl: ""
              });
            }
          } else {
        for (let i = 0; i < 5 ; i++) {
              this.imgArr.push({
                bannerUrl: ""
              });
            }
          }
        })
        .catch(err => {
          return;
        });
    },
    validatePass5(rule, value, callback) {
      if (this.imgArr.length == 0) {
        callback(new Error("至少上传一张banner图"));
      } else {
        callback();
      }
    },
    delImgFun(id,index) {
      console.log("功能暂未开发");
      if (id) {
        this.$post(
        "/baseconfig/questionTypeConfig/deleteBaseBannerById",
        {bannerId:id},
        3000,
        true,
        2
      )
        .then(res => {
          if (res.status == 0) {
            this.getDataImg();
            this.$message({
                  message: "操作成功",
                  type: "success",
                  duration: 1000
                });
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(err => {
          return;
        });
      }else{
        let obj={ bannerUrl: ""}
        this.$set(this.imgArr,index,obj)
        
      }
    },
    onSubmitImg() {
      this.$refs["ruleFormImg"].validate(valid => {
        if (valid) {
          let bannerContents = [];
          for (let i in this.imgArr) {
            if (this.imgArr[i].bannerUrl) {
              bannerContents.push(this.imgArr[i]);
            }
          }
          let params = {
            qbQuestionBankBanners: [...bannerContents]
          };
          this.$post(
            "/baseconfig/questionTypeConfig/modifyBaseBannerConfig",
            params,
            2000,
            true,
            2
          )
            .then(res => {
              if (res.status == 0) {
                this.$message({
                  message: "操作成功",
                  type: "success",
                  duration: 1000
                });
              } else {
                this.$message.error(res.message);
              }
            })
            .catch(err => {
              return;
            });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    }
  },
  beforeRouteLeave: resetKeepAlive
};
</script>
<style lang="less" >
.questionOption {
  .table_box {
    .el-form-item__content {
      line-height: 0;
    }
  }
  .el-input {
    width: 50% !important;
  }
  .sortUp {
    width: 19px;
    height: 19px;
    display: inline-block;
    background: url("../../../assets/sortUp.png") no-repeat;
    background-size: cover;
    cursor: pointer;
  }
  .sortDown {
    width: 19px;
    height: 19px;
    display: inline-block;
    background: url("../../../assets/sortDown.png") no-repeat;
    background-size: cover;
    cursor: pointer;
  }
}
.delImg {
  width: 18px;
  height: 18px;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
  background: url("../../../assets/close_.png") no-repeat;
  background-size: cover;
}
</style>
